@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-primary: #0CA5E9;
        --color-primary-dark: #0285C7;
        --color-primary-darker: #0968A0;
        --color-text-base: #212529;
        --color-text-muted: #FFFFFF;
    }
    .theme-blue {
        --color-primary: #3C82F6;
        --color-primary-dark: #2564EB;
        --color-primary-darker: #1D4ED8;
        --color-text-base: #212529;
        --color-text-muted: #FFFFFF; 
    }
    .theme-green {
        --color-primary: #14B981;
        --color-primary-dark: #0A9768;
        --color-primary-darker: #067856;
        --color-text-base: #212529;
        --color-text-muted: #FFFFFF;  
    }
    .theme-pink {
        --color-primary: #EC4999;
        --color-primary-dark: #DB2977;
        --color-primary-darker: #BE195D;
        --color-text-base: #212529;
        --color-text-muted: #FFFFFF;
    }
    .theme-teal {
        --color-primary: #17B8A6;
        --color-primary-dark: #0F9488;
        --color-primary-darker: #0E766E;
        --color-text-base: #212529;
        --color-text-muted: #FFFFFF;
    }
}

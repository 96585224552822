.react-datepicker-wrapper {
    display: block;
}

.react-datepicker__input-container {
    display: block;
}

.react-datepicker-popper {
    width: 350px;
}

/**
 * Notie - https://unpkg.com/notie@4.3.1/dist/notie.min.css
 */
.notie-container {
    font-size: 1.2rem;
    height: auto;
    left: 0;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 2000;
    box-sizing: border-box;
    -o-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    -ms-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5)
}

@media screen and (max-width: 900px) {
    .notie-container {
        font-size: 1.4rem
    }
}

@media screen and (max-width: 750px) {
    .notie-container {
        font-size: 1.2rem
    }
}

@media screen and (max-width: 400px) {
    .notie-container {
        font-size: 1rem
    }
}

.notie-background-success {
    background-color: #28A745
}

.notie-background-warning {
    background-color: #FFC205
}

.notie-background-error {
    background-color: #DC3446
}

.notie-background-info {
    background-color: #4d82d6
}

.notie-background-neutral {
    background-color: #a0a0a0
}

.notie-background-overlay {
    background-color: #fff
}

.notie-textbox {
    color: #fff;
    padding: 5px
}

.notie-textbox-inner {
    margin: 0 auto;
    max-width: 900px
}

.notie-overlay {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1
}

.notie-button {
    cursor: pointer
}

.notie-button, .notie-element {
    color: #fff;
    padding: 10px
}

.notie-element-half {
    width: 50%
}

.notie-element-half, .notie-element-third {
    display: inline-block;
    box-sizing: border-box
}

.notie-element-third {
    width: 33.3333%
}

.notie-alert {
    cursor: pointer
}

.notie-input-field {
    background-color: #fff;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    outline: 0;
    padding: 10px;
    text-align: center;
    width: 100%;
    box-sizing: border-box
}

.notie-select-choice-repeated {
    border-bottom: 1px solid hsla(0, 0%, 100%, .2);
    box-sizing: border-box
}

.notie-date-selector-inner {
    margin: 0 auto;
    max-width: 900px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none
}

.notie-date-selector-inner [contenteditable], .notie-date-selector-inner [contenteditable]:focus {
    outline: 0 solid transparent
}

.notie-date-selector-up {
    transform: rotate(180deg)
}

/**
 * Hide attachment icon in the trix editor
 */
trix-toolbar .trix-button--icon-attach {
    display: none;
}

.group:hover .group-hover\:block {
    display: block;
}
